* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    backface-visibility: hidden;
  }
  
.colourModeButton {
  display: block;
  width: 2.5em;
  height: 2.5em;
  font-size: larger;
  border-radius: 100%;
  text-decoration: none;
  display: inline-block;
  border: none;
  transition: background-color 0.15s, color 0.15s;
}
  
  .left {
    float: left;
  }
  
  .right {
    float: right
  }
  
  .clear {
    clear: both;
  }

  .spinner{
    display: flex;
    width: 100%;
    height: 25vh;
    justify-content: center;
    align-items: center;
  }
  
  .main-container {
    
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
    text-align: left;
    line-height: 1.5;
    background-attachment: fixed;
    background-size: cover;
    background-position: center;
    min-width: 300px;
    min-height: 100vh;
  
  }
  
  hr{
    border: none;
    border-bottom: 1px solid #ccc;
  }
  
  main {
    margin: 0;
    background: #f4f4f4;
    width: 75vw;
    padding: 1em 2em;
    position: relative;
    left: 50%;
    overflow-x: hidden;
    transform: translateX(-50%);
    min-height: 100vh;
  
  }
  
  .container{
    width: 100%;
    display: flex;
    margin-top: 1em;
    font-size: 1rem;
    justify-content: space-between;
  }
  
  .post_content_container {
    font-family: Charter;
  }

  .post_content_container h1,  .post_content_container h2,  .post_content_container h3  {
    font-family: Montserrat, sans-serif;
    font-weight: 900;
  }


  .post_container a{
    text-decoration: none;
  }
  
  .post_title{
    /* padding: 0em 0 0.5em 0; */
    font-size: 2rem;
  }
  
  header {
    display: inline-block;
    position: relative;
    width: 75vw;
    left: 50%;
    transform: translateX(-50%);
    padding: 1vw;
}
  
  .topbar-section{
    display: flex;
    flex-direction: column;
    padding: 0 2em;
  }
  
  .control-nav {
    width: 75vw;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    padding: 1em;
  }
  
  .control-nav a,
  .control-nav button {
    border: none;
    text-decoration: none;
    padding: 10px;
    margin: 0 2px;
    font-size: 11px;
    display: inline-block;
    transition: background-color 0.15s, color 0.15s;
  }
  
  .jamesPic {
    display: inline-block;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    height: 80px;
    width: 80px;
    border-radius: 100%;
    margin-left: 30px;
  
  }
  
  .navbar a, .btn {
    text-decoration: none;
    padding: 0.75em 2em;
    margin: 0 1em 0 0;
    display: inline-block;
    transition: background-color 0.15s, color 0.15s;
  }
  
  .navbar {
    font-weight: 500;
    width: 100%;
    text-align: left;
  }
  
  
  .title {
    display: flex;
    justify-content: space-between;
    font-weight: 300;
    margin-bottom: 1em;
  }
  
  .title h1 {
    display: inline;
    font-weight: 300;
    margin-right: 10px;
  }
  
  .title h3 {
    display: inline;
    font-weight: 100;
  }
  

/* CUSTOM ICONS */
.icon {
height: 1.25em;
width: 1.25em;
display: inline-block;
vertical-align: sub;
}
.hk47-icon {
  background: url('symbol.png');
  background-size: auto;
  background-size: cover;
}
.hanzibase-icon {
  background: url('hanzibase-icon.png');
  background-size: auto;
  background-size: cover;
}

  /*SEARCHBAR*/
  
  .search {
    text-align: right;
    float: right;
    display: block;
    transition: all .2s;
    margin-bottom: 1em;
  }
  
  .searchInput {
    height: 3em;
    outline: none;
    padding: 0 10px 0 15px;
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
  }
  
  .searchInput::placeholder {
    font-style: italic;
    font-family: 'Montserrat';
  }
  
  .searchButton {
    position: relative;
    padding: 0px 13px;
    left: 10px;
    transition: color 0.3s, background-color 0.3s, border 0.1s;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  
  .posts_container{
    max-width: 1000px;
    width: 100%;
  }
  
  .sidebar_container{
    width: 300px;
  }
  
  .sidebar{
    margin: 0 0 0 2em;
    padding: 0em 1em 2em 1em;
    min-height: 300px;
    margin-bottom: 2em;
    clear: both;
  }
  
  .sidebar_post{
    text-decoration: none;
    transition: 0.2s;
    display: flex;
    flex-direction: column;
  }
  .sidebar_post_content{
    padding: 1em;
  }
  
  .sidebar-btn{
    width: 100%;
    text-align: center;
    border: none;
    white-space: nowrap;
  }
  
  .sidebar_post_image{
    width: 100%;
    height: 4em;
    background-position: center;
    background-size: cover;
  }
  
  /*Pagination*/
  .pagination{
    list-style: none;
    display: flex;
    text-align: center;
    justify-content: center;
  }
  .pagination li{
    padding: 1em;
    font-size: 1.5rem;
    /* font-family: "Public Sans"; */
  }
  .pagination a{
    text-decoration: none;
  }
  .page-item + .active{
    font-weight: 800;
  }
  
  .page-num{
    margin-bottom: 1em;
  }
  
  .readMore {
    margin: 30px 0 15px 0;
    padding: 15px;
    text-decoration: none;
    transition: color 0.2s, background-color 0.2s, border 0.2s;
  }
  
  .readMore:hover {
    text-decoration: none;
  }
  
  
  a.loadMore {
    text-decoration: none;
    border-style: solid;
    margin: 15px 0;
    padding: 15px;
    width: 100%;
    transition: color 0.2s, background-color 0.2s, border 0.2s;
  }
  
  
  a.loadMore:hover {
    text-decoration: none;
  }
  
  /*BANNER*/
  .banner {
    padding: 30px 10px 0 10px;
    /* margin-top: 2em; */
    font-size: 1.2rem;
    text-align: center;
  }
  
  .banner h3 {
    font-size: 24px;
  }
   
  /*POST PAGE STUFF*/ 
  .post {
    width: 100%;
    max-width: 1000px;
    min-height: 200px;
  }
  
  .post_link{
    width: 100%;
    min-height: 200px;
    margin-bottom: 3em;
    color: inherit;
    transition: color 0.2s, background-color 0.2s;

    color: white;
    background-color: rgb(53, 111, 42);

    border: 1px solid #ccc;
    border-bottom-color: #bbb;
  }

  .post_link_details {
    width: 100%;
    padding: .5em;
    display: flex;
    justify-content: space-between;

    background-color: rgb(25, 49, 20);
    color: white;
  }

  .post_link h2 {
    padding: .25em 0;
  }

  .post_link:hover {
    background-color: rgb(25, 49, 20);
  }

  .post_link_details p {
    padding: 0 1em;
  }
  .post_link_details_bold {
    font-weight: 800;
  }
  
  .post_container img {
    max-width: 90%;
    height: auto;
  }
  
  .post h1 {
    margin: 0 0 10px 0;
  }
  
  .post_container {
    margin: 1em 2em;
    font-size: 1rem;
  }
  .post_container ul{
    list-style: inside;
  }
  
  .post-body p, .post-body code{
    padding: 0 0 1em 0;
  }
  
  .sidebar hr {
    margin: 10px 0;
  }
  
  .sidebar h3 {
    text-align: center;
    padding-bottom: 0.5em;
    margin-top: 1em;
  }
  
  .section {
    margin-bottom: 2em;
    color: inherit;
    transition: color 0.2s, background-color 0.2s;
  }
  
  .post_link img {
    opacity: 1;
    transition: opacity 0.5s linear;
  }
  
  .unlinkStyle {
    text-decoration: none;
    color: inherit;
  }
  
  /*CLass for timestamp on posts/projects*/
  .timestamp {
    font-size: 1em;
    text-align: right;
    font-style: italic;
    margin: 0;
    margin-bottom: 5px;
  }
  
  .post_link:hover img {
    opacity: 0.7;
  }
  
  .post_thumbnail {
    background-position: center;
    background-size: cover;
    width: 100%;
    height: 100px;
    object-fit: cover;
    filter: brightness(85%);
    }

    .post_header_image {
      background-position: center;
      background-size: cover;
      width: 100%;
      max-height: 400px;
      object-fit: cover;
      }
  
  
  /*FOOTER*/
  footer{
    margin-top: 5em;
  }
  .footer-email{
    text-align: center;
  padding: 2em 0;
  }
  .footer-buttons{
    display: flex;
    justify-content: center;
  }
  
  .social-button {
    display: flex;
    font-size: 2rem;
    opacity: 0.90;
    margin: 0 0.5em;
    border-radius: 100%;
    border: none;
    width: 2em;
    height: 2em;
    transition: background-color 0.4s, height 0.4s, width 0.4s, color 0.4s, opacity 0.4s;
    justify-content: center;
    align-items: center;
    text-decoration: none;
  }
  .social-button:focus{
    outline: 0;
  }
  
  /* backend */
.backend-button {
  background-color: #011627;
  color: white;
  padding: 1em;
  border-radius: 1em;
}
.backend-list-item{
  margin: 1em;
  padding: 1em;
  background-color: #ddd;
}


  /* homepage */
  
  .mail-banner {
    width: 100%;
    padding: 1em;
    margin: 1em 0;
    font-family: "Open Sans";
    font-weight: 500;
    border-radius: 1em;
    }
  
  
    /*HOMEPAGE*/
    .homepage-content {
        display: flex;
        /* flex-direction: row-reverse; */
        margin: 2em;
        /* font-size: 1.15rem; */
        /* justify-content: center; */
        /* font-family: "Public Sans"; */
    }
    .homepage-content p{
      margin-bottom: 0.5em;
      text-align: justify;
    }
    .homepage-content div {
        /* width: 45%; */
        /* max-width: 1000px; */
        text-align: initial;
        margin-top: 1em;
        padding: 1em;
    }
    .homepage-profile-pic {
      max-width: 500px;
      border-radius: 15%;
      width: 100%;
    }
    .homepage-profile-pic-top {
        display: none;
    }
    .homepage-quick-links {
        display: flex;
        list-style: none;
        /* margin-top: 3em; */
        flex-direction: column;
    }
    .homepage-quick-links a {
        text-decoration: none;
        transition: 0.2s;
        /* font-size: 1.25rem; */
    }
    .homepage-quick-links li {
        padding: 0.5em 0;
    }

    /* Homepage specific sections */
    .homepage-left{
      width: 80%;

      margin-right: 1em;
    }
    .homepage-topics{
      max-width: 100% !important;
    }

    /* topic links */
    .homepage-topic-links {
      list-style: none;
      display: flex;
      flex-wrap: wrap;
    }
    .homepage-topic-links a {
      text-decoration: none;
      transition: 0.4s;
      padding: 1em;
      
      /* font-size: 1.25rem; */
  }
  .homepage-topic-links li {
    margin: 0.25em;
    padding: .6em 0;
    transition: 0.4s;
  }

    .bottom-links {
      flex-direction: row;
justify-content: space-evenly;
text-align: center;
    }
    .homepage-gallery {
        display: grid;
        grid-template-columns: 50% 50%;
        list-style: none;
        padding: 1em;
    }
    .homepage-gallery img {
        width: 100%;
    }
    .homepage-email-msg {
      margin: 1em 0 2em !important;
      padding: 0 !important;
    }
    .homepage-contact {
      min-width: 100%;
    }

    .homepage-contact-links {
      margin-top: 2em;
      list-style: none;
      display: flex;
      justify-content: center;
    }
    .homepage-contact-links a {
      font-size: 1.5em;
      padding: .33em .5em;
      margin: 1em;
    }


    


    /* contact Page */
  
    .captchaStyle{
      width: 100%;
      display: flex;
      justify-content: center;
  }
  textarea {
      width: 100%;
      padding: 1em;
      margin: 1em 0;
      resize: vertical;
      font-family: "Open Sans";
  }
  
  .banner h1 {
      text-align: center;
      /* display: none; */
  }
  
  .contact-form {
      text-align: center;
      width: 50%;
      margin: auto;
  
  }
  
  .submit-button {
      padding: 1em 2em;
      font-size: 1rem;
      transition: 0.2s;
      margin: 3rem 0;
  }
  
  .row {
      padding: 0;
  }
  
  .form-control{
    width: 100%;
    padding: 1em;
    margin: 1em 0;
    font-family: Public Sans;
  }
  
/* Resources page */
.resource-container section, 
.resource-container hr {
  margin-bottom: 2em;
}
.resource-container p {
  margin-bottom: 1em;
}

.resource-container img {
  max-width: 50%;
}

.resource-ul-no-style {
  list-style: none !important;
}

.sublist {
  margin-left: 35px;
  list-style: lower-alpha;
  list-style-position: inside;
  font-size: small;
}

/* Used in some posts */
.superlist {
  margin-left: 20px;
  font-size: large;
}

.resource-container ol li {
  list-style-position: inside;
  margin-bottom: 7px;
}

.li-book li {
  margin: 5px 0 0 5px;
  font-size: 1em;
}

  
  @media only screen and (max-width: 650px) {
      form {
          width: 90%;
          margin: auto;
  
      }
  
      .submit-button {
          padding: 1em 2em;
          font-size: initial;
      }
  }
  
  
  /* Media queries */
  
    @media only screen and (max-width: 985px) {
        .homepage-content{
            flex-direction: column;
            margin: 0em;
        }
        .homepage-profile-pic-top {
            display: initial;
            width: 15em;
            margin-bottom: 2em;
            border-radius: 10%;
        }
        .homepage-profile-pic{
            display: none;
        }
        .homepage-content div{
            width: 100%;
            /* text-align: center; */
        }
        .homepage-quick-links li {
            padding: 1.5em 0em;
            /* text-align: center; */
        }
        .banner{
          margin-top: 4em;
        }
    }
  
  
  
  @media only screen and (max-width: 1185px) {
    header, main, .control-nav {
        width: 100vw;
    }
  
    .x2obj {
        width: 90%
    }
  
  
    .sidebar_container {
        display: none;
    }
  
  
    .post {
        width: 100%;
  
    }
    .navbar{
        width: 100%;
    }
  
  }
  
  @media only screen and (max-width: 985px) {
  
    .sidebar{
      display: none;
    }
  
    .banner {
        font-size: 1.8vw;
  
    }
  
    .banner.h3 {
        font-size: 1.8vw;
    }
  
  
    .title {
        left: 70px;
    }
  
    .navbar {
        overflow: hidden;
        left: 0;
    }
  
    .navbar a{
        padding: 0.75em 1.5em;
    }
  
    .jamesPic {
        top: 25%;
        margin-left: 10px;
        height: 40px;
        width: 40px;
    }
  
  
  
  }
  
  
  @media only screen and (max-width: 650px) {
    body{
        margin: 0;
        font-size: inherit;
    }
    
  
    .navbar{
        text-align: center;
        display: flex;
        margin-bottom: 1em;
        justify-content: center;
  
    }
    .navbar a {
        font-size: 90%;
        width: 25%;
        padding: 0.75em 0;
        margin: 0 0.3em; 
    }
    .title { 
        position: initial;
        margin-bottom: 1em;
    }
  
    .title h1{
        display: inherit;
        margin-right: 0;
        font-size: 32px;
        text-align: center;
    }
  
    .title h3 {
        display: none;
    }
   
    .row input{
        font-size: 1rem;
    }
    .row textarea{
        font-size: 1rem;
    }
   
    .post_container {
        margin: 5px 15px;
    }
  
    main,
    header,
    .control-nav {
        width: 100%;
    }
  
  
    body {
        margin: 0;
    }

    main {
      border: none;
      padding: 1em;
    }
  
    .banner {
        font-size: 1rem;
    }
  
  
  
    .search {
        text-align: center;
    }
  
    .searchInput {
        width: 80%;
    }
  
    .jamesPic {
        display: inline-block;
        position: absolute;
        top: 35%;
        transform: translateY(-50%);
  
        border-radius: 100%;
        margin-left: 10px;
  
    }
  
    .social-button{
        width: 2em;
        height: 2em;
    }
  }
  
  @media only screen and (max-width: 488px) {
    footer {
        font-size: 70%;
    }
  
    .banner {
        font-size: 12px;
    }
  
    .banner h3 {
        font-size: 22px;
        padding-bottom: 20px
    }
    .title{
        margin-bottom: 2em;
    }
    .topbar-section{
        padding: 0 0.2em;
    }
    .post_container{
        font-size: 1rem;
    }
    
  
    .post_title{
      font-size: 1.25rem;
    }
  }
  
  @media only screen and (max-width: 300px) {
    .banner {
        width: 100%;
    }
    main{
        padding: 1em 0;
    }
  }