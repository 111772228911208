.colourModeButton {
  background-color: #eee;
  color: #666;
  border: rgb(53, 111, 42) 2px solid;
}

.colourModeButton:hover {
  background-color: #222;
  color: #eee;
}

.main-container {
    color: #222 ;
    background-image: linear-gradient(
    rgba(21, 24, 19, 0.281),
    rgba(48, 71, 37, 0.83)), url('https://jsparrow.uk/img/background.webp');
    
}

main {

  border-left: 1px solid rgb(194, 194, 194);
  border-right: 1px solid rgb(194, 194, 194);
}

.post_container a{
    color: darkcyan;
  }
  .post_container img {
    border: 1px solid #ccc;
  }
  header {
    background-color: rgb(53, 111, 42);
    border: 1px solid #4b4b4b4b;
}

.control-nav {
    background-color: #222;
    color: #222;
  
  }

  .control-nav a,
  .control-nav button {
    background-color: #f4f4f4;
    color: rgb(53, 111, 42);
  }

  .control-nav a:hover {
    background-color: #011627;
    color: #f4f4f4;
  }

  .navbar a, .btn {
    background-color: #f4f4f4;
    color: rgb(53, 111, 42);
  }

  .btn{
    border: rgb(53, 111, 42) 2px solid;
  }

  .navbar a:hover, .btn:hover {
    background-color: rgb(25, 49, 20);
    color: #f4f4f4;
  }
    
  .title {
    color: #fff;
  }

  .searchInput {
    background-color: #fff;
    border: 1px solid #80808080;
    color: black;
  }

  .searchButton {
    background-color: rgb(53, 111, 42);
    border: solid 2px rgb(53, 111, 42);
    color: #fff;
  }

  .sidebar{
    background-color: #f4f4f4;
    border-left: rgba(144, 144, 144, 0.5) 1px solid;
  }

  .sidebar_post{
    background-color: #f4f4f4;
    color: #222;
  }

  .sidebar-btn{
    color: #222;
    border: none;
  }

  .sidebar_post:hover {
    background-color: rgb(53, 111, 42);
    color: #fff;
  }

  .pagination a{
    color: #222;
}

.readMore {
    background-color: rgb(53, 111, 42);
    color: white;
}

  
.readMore:hover {
    border: solid 5px rgb(53, 111, 42);
    color: rgb(53, 111, 42);
    background-color: white;
  }

  a.loadMore {
    background-color: rgb(53, 111, 42);
    border: solid 5px rgb(53, 111, 42);
    color: white;
}

a.loadMore:hover {
    border: solid 5px rgb(53, 111, 42);
    color: rgb(53, 111, 42);
    background-color: white;
  }



  .section {
    background-color: #f4f4f4;
  }

  .post_thumbnail {
    background-image: linear-gradient(rgba(235, 145, 43, 0.799), rgba(232, 141, 131, 0.899));
    border: 1px solid #ccc;
    border-bottom: none;
  }

  .social-button {
color: white;
    background-color:
    rgb(71, 113, 177);
    box-shadow: 0 3px 7px 0px
    black;
  }

  .social-button:hover {
    background-color: lightgrey;
    color: black;
    box-shadow: inset 0px 1px 3px 0px black;
  }

  .mail-banner {
    background-color: rgba(190, 255, 190, 0.34);
    border: 1px solid #62b062;
    }

    .homepage-content {
        color: #151515;
    }

    .homepage-quick-links a {
        color: rgb(71, 127, 177);
    }
    .homepage-quick-links a:hover {
        color: rgba(0, 0, 0, 0.4);
    }

    .homepage-topic-links a {
        color: black;
    }

    .homepage-topic-links li {
        background-color: rgb(234, 234, 234);
    }
    .homepage-topic-links li:hover {
        background-color: rgba(0, 0, 0, 0.4);
    }

    .homepage-gallery {
        background-color: #1fc1c1;
    }

    .submit-button {
        background-color: #4771b1;
        color: #fff;
        border: 2px solid #4771b1;
    }

    .submit-button:hover {
        background-color: #fff;
        color: #4771b1;
        border: 2px solid #4771b1;
    }

    .form-control{
        border: 1px solid #9d9d9d;
      }