html {
    scroll-behavior: smooth;
}

.cv-text {
    padding: 2em 2em 0 2em;
    font-size: 1.2em;
    text-align: center;
}

.expandable {
    background: #595959;
    overflow: hidden;
    transition: all .5s ease-in-out;
    display: none;
    grid-column: 1 / -1;
    padding: 0 1em;
    color: #fff;
}


.expanded {
    display: block;
    line-height: 1.5;
    padding-top: 1em;
    padding-bottom: 1em;
    margin-bottom: 1em;
    color: #fff;
    height: initial;
}

.expanded .expandable-image {
    display: block;
}

.expandable-image {
    display: none;
}
.expandable-image img{
    width: 100%;
}

.expandable-top {
    display: flex;
    margin-top: 1em;
    justify-content: center;
}
.expandable-top div{
    width: 50%;
}
.expandable-top img{
    width: 50%;
    place-self: baseline;
}

.expandable-middle{
    text-align: center;
display: flex;
justify-content: space-evenly;
padding: 1em;
flex-wrap: wrap;
}
.expandable-middle div{
    padding: 1em;
    color: #dfdfdf;
}


.expandable-bottom {
    text-align: center;
    padding: 2em 1em 1em 1em;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;

}

.expandable-bottom .btn{
    color: #262626;
}
.expandable-bottom .btn:hover{
    color: white;
}

.expandable-bottom a {
    text-decoration: none;
    border: none;
    font-weight: 500;
    margin: 1em;
}

.expandable-text {
    padding: 0 1em 1em 1em;
}

.mini-feature-list{
    background: #0000001f;
    display: flex;
    padding: 0.5em;
    width: 100%;
    flex-wrap: wrap;
}
.mini-feature-list div{
    padding-left: 5px;
}

.portfolio-grid-container{
    width: 80%;
    margin: auto;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-auto-flow: row dense;
    grid-gap: 1em;
}

.portfolio-title {
    text-align: center;
    font-size: 2.5rem;
    margin: 1em;
}


.portfolio-link {
    border: 1px solid #0000001f;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    text-align: center;
    margin-top: 1em;
    color: #595959;
    text-decoration: none;
    transition: 0.2s;
    cursor: pointer;
}

.portfolio-link:hover {
    background-color: rgb(74, 90, 69);;
    color: #fff;
}

.portfolio-link-image {
    display: flex;
    justify-content: center;
    align-items: center;

}

.portfolio-link img {
    border-radius: 100%;
    padding: 1em;
    width: 10rem;
    height: 10rem;
}

.portfolio-link-text {
    padding: 1em;
    font-weight: 400;
    width: 80%;
}

.portfolio-link-text p {
    font-size: 1.25rem;
}

.portfolio-link-text h3 {
    font-size: 1.5rem;
}

.sourcePrivate{
    color: #a1a1a1;
    margin: auto 0;
    padding: 1em 0;
}

@media only screen and (min-width: 2560px){
    .expandable-text{
        font-size: 1.25rem;
    }
    .expandable-top img {
        width: 40%;
    }
}

@media only screen and (max-width: 985px) {
    .portfolio-link-text {
        font-size: 0.8rem;
        width: 65%;
    }

    .portfolio-link-image {
        width: 35%;
    }
    .expandable-top{
        flex-direction: column;
    }
    .expandable-top div{
        width: 100%;
    }
    .expandable-image{
        margin-bottom: 1em;
    }
}

@media only screen and (max-width: 650px) {
    .portfolio-grid-container{
        grid-template-columns: repeat(1, 1fr);
        width: 100%;
    }
    .portfolio-link-text {
        width: 60%;
    }

    .portfolio-link-image {
        width: 40%;
    }
    .main{
        padding: 1em 0;
    }
    .expandable-top img {
        width: 100%;
        padding-bottom: 1em;
    }
}

@media only screen and (max-width: 488px) {
    .portfolio-link-text {
        font-size: 0.6rem;
    }
}
