.dark {
    background-color: #000;
    color: #fff;
}
.dark .colourModeButton {
    background-color: #222;
    color: #eee;
    border: rgb(53, 111, 42) 2px solid;
  }
  
.dark  .colourModeButton:hover {
    background-color: #eee;
    color: #222;
  }

.dark main {
    background-color: #222;
    border-left: 1px solid rgb(23, 23, 23);
    border-right: 1px solid rgb(23, 23, 23);
}

.dark .main-container {
color: #eee;
background-image: linear-gradient( rgba(0, 0, 0, 0.81), rgba(0, 40, 3, 0.8)), url('https://jsparrow.uk/img/dark.webp');
}

.dark .post_container a{
color: rgb(115, 184, 245);
}
.dark .post_container img {
    border: 1px solid #444;
}


.dark header {
background-color: rgb(53, 111, 42);
border: 1px solid #dfdfdf4b;
}

.dark .control-nav {
background-color: #222;
color: #fff;

}

.dark .control-nav a,
.dark .control-nav button {
background-color: #222;
color: rgb(53, 111, 42);
}

.dark .control-nav a:hover {
background-color: #011627;
color: #fff;
}

.dark .navbar a, 
.dark .btn {
background-color: #222;
color: #eee;
}

.dark .btn{
border: rgb(53, 111, 42) 2px solid;
}

.dark .navbar a:hover, .dark .btn:hover {
background-color: #333;
color: #fff;
}

.dark .title {
color: #fff;
}

.dark .searchInput {
background-color: #222;
border: 1px solid #80808080;
color: white;
}

.dark .searchButton {
background-color: rgb(53, 111, 42);
border: solid 2px rgb(53, 111, 42);
color: #fff;
}

.dark .sidebar{
background-color: #222;
border-left: rgba(144, 144, 144, 0.5) 1px solid;
}

.dark .sidebar_post{
background-color: #222;
color: #fff;
}

.dark .sidebar-btn{
    color: #fff;
    border: none;
}

.dark .sidebar_post:hover {
background-color: rgb(53, 111, 42);
color: #fff;
}

.dark .pagination a{
color: #fff;
}

.dark .readMore {
background-color: rgb(53, 111, 42);
color: white;
}


.dark .readMore:hover {
border: solid 5px rgb(53, 111, 42);
color: rgb(53, 111, 42);
background-color: black;
}

.dark a.loadMore {
background-color: rgb(53, 111, 42);
border: solid 5px rgb(53, 111, 42);
color: white;
}

.dark a.loadMore:hover {
border: solid 5px rgb(53, 111, 42);
color: rgb(53, 111, 42);
background-color: black;
}

.dark .post_link{
    background-color: #333;

    border: 1px solid #444;
  } 
  .dark .post_link:hover {
    background-color: #151515;
  }

 .dark .post_link_details {
  background-color: #151515;
    color: white;
  }

.dark .section {
background-color: #222;
}

.dark .post_thumbnail {
    background-color: black;
    border: 1px solid #444;
}

.dark .social-button {
color: white;
background-color:
rgb(71, 113, 177);
box-shadow: 0 3px 7px 0px
black;
}

.dark .social-button:hover {
background-color: lightgrey;
color: black;
box-shadow: inset 0px 1px 3px 0px black;
}

.dark .mail-banner {
background-color: rgba(190, 255, 190, 0.34);
border: 1px solid #62b062;
}

.dark .homepage-content {
color: #eee;
}

.dark .homepage-quick-links a {
color: rgb(115, 184, 245);
}
.dark .homepage-quick-links a:hover {
color: #fff;
}

.dark .homepage-topic-links a {
color: #eee;
}

.dark .homepage-topic-links li {
background-color: rgb(61, 61, 61);
}
.dark .homepage-topic-links li:hover {
background-color: rgba(135, 135, 135, 0.4);
}

.dark .homepage-gallery {
background-color: #1fc1c1;
}

.dark .submit-button {
background-color: #4771b1;
color: #fff;
border: 2px solid #4771b1;
}

.dark .submit-button:hover {
background-color: #fff;
color: #4771b1;
border: 2px solid #4771b1;
}

.dark .form-control{
border: 1px solid #9d9d9d;
}


/* PORTFOLIO DARK */

.dark .portfolio-link{
    color: #eee;
}
.dark .portfolio-link:hover {
    background-color: #356f2aff;
}

.dark .expandable-bottom .btn {
    color: #eee;
    border-color: rgba(0, 0, 0, 0);
}